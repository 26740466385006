import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Confetti from "react-dom-confetti";
import { Layout } from "../components";
import $ from "jquery";
import ThankYouPageHand from "../components/thankYouPageHand";

const config = {
  angle: 90,
  spread: 100,
  startVelocity: 60,
  elementCount: 150,
  dragFriction: 0.1,
  duration: 2500,
  stagger: 0,
  width: "7px",
  height: "10px",
  colors: [
    "#E68F17",
    "#FAB005",
    "#FA5252",
    "#E64980",
    "#BE4BDB",
    "#0B7285",
    "#15AABF",
    "#EE1233",
    "#40C057",
  ],
};

const ContactThankYou = ({ pageContext: { slug, langKey } }) => {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      $(".hand-container").addClass("animated");
      setShowConfetti(true);
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Layout
      seo={{
        title: "Kontakt potwierdzenie",
        href: slug,
        lang: langKey,
        noindex: true,
      }}
    >
      <div className="page_thanks">
        <div className="hand-container">
          <ThankYouPageHand />
        </div>
        <div className="text-container">
          <p className="desc">
            Dziękujemy za wypełnienie formularza. <br /> <br />
            Do usłyszenia!
          </p>
          <p>
            <Link to="/chatbot-ai" className="btn btn-box">
              <strong>Powrót</strong>
            </Link>
          </p>
        </div>
        <Confetti active={showConfetti} config={config} className="confetti" />
      </div>
    </Layout>
  );
};

export default ContactThankYou;
